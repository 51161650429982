import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import { StyledArticleCard } from "./StyledArticleCard";

export const StyledArrowLink = styled.a`
  display: block;
  ${StyledArticleCard} & {
    margin-top: 30px;
    ${breakpoint.phoneLandscape} {
      margin-top: 15px;
    }
    ${breakpoint.phone} {
      margin-top: 15px;
    }
  }
`;
