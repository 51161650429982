import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import u from "../../helpers/unit";
import { ArticleContainer } from "../Styles/StyledArticleDetailPage";
import { Intro } from "../Styles/StyledLandingPage";

export const BlockQuote = styled.p`
  font-size: 3rem;
  line-height: 1.4em;
  ${breakpoint.tabletPortrait} {
    font-size: 2.5rem;
  }
  ${breakpoint.phone} {
    font-size: 2.1rem;
  }
  ${breakpoint.phoneLandscape} {
    font-size: 1.8rem;
  }

  ${Intro} & {
    color: ${colors.black};
    padding-bottom: ${u(0.5)};
  }
`;
