import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledVideoModalContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* width: ${u(14)}; */
  height: auto;
  ${breakpoint.tabletLandscape} {
    grid-template-columns: 2fr;
    grid-template-rows: 1fr;
  }
`;

export const VideoModalInfo = styled.div`
  padding: ${u(0.5)} 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoint.phoneLandscape} {
    padding: 15px;
  }
  ${breakpoint.phone} {
    padding: 15px;
  }
`;
