import styled from "styled-components";
import { colors } from "../../helpers/consts";

export const StyledVideoModalVariant = styled.button`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 26px;
  align-items: center;
  font-size: 1.1rem;
  background-color: ${colors.lightGray};
  border: 0.5px solid ${colors.gray300};
  color: ${colors.gray300};
  &.was-played {
    color: ${colors.green400};
    border: 0.5px solid ${colors.green400};
    background-color: ${colors.green300};
  }
`;

export const VideoModalVariants = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 60px;
  grid-gap: 5px;
  padding-top: 20px;
`;
