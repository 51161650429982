import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";

export const StyledNavLogo = styled.div`
  width: 150px;

  ${breakpoint.tabletPortrait} {
    width: 120px;
  }
`;
