import styled from "styled-components";
import u from "../../helpers/unit";
import { breakpoint } from "../../helpers/consts";
import { VideoSection } from "./StyledLandingPage";

export const StyledTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  ${breakpoint.phone} {
    gap: 20px;
  }
  ${breakpoint.phoneLandscape} {
    gap: 20px;
  }
  ${VideoSection} & {
    grid-area: textblock;
  }
  ${breakpoint.tabletLandscape} {
  }
`;
