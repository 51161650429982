import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledLogoGrid = styled.div`
  padding: ${u(1)} 0;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
  // white is used for black and white color logotypes with blending mode
  background-color: white;

  ${breakpoint.tabletLandscape} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${breakpoint.tabletPortrait} {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
`;
