import { StyledNavLogo } from "../Styles/StyledNavLogo";
import Link from "./Link";
import Img from "./Img";

interface NavLogoProps {
  className?: string;
  src: string;
}

const NavLogo = ({ className, src }: NavLogoProps) => {
  return (
    <StyledNavLogo className={className}>
      <Link className={`no-hover`} href="/">
        <Img src={src} />
      </Link>
    </StyledNavLogo>
  );
};

export default NavLogo;
