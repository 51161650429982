import styled from "styled-components";
import { colors } from "../../helpers/consts";
import { StyledArrowLink } from "./StyledArrowLink";
import { StyledArticleCard } from "./StyledArticleCard";

export const StyledLink = styled.a<{ href?: string }>`
  letter-spacing: 0em;
  font-size: 1.4rem;
  text-decoration: none;

  cursor: pointer;

  &.active {
    color: ${colors.orange400};
    cursor: default;
  }

  &:hover {
    color: ${colors.orange400};
  }
  ${StyledArrowLink} & {
    text-transform: uppercase;
    color: ${colors.orange400};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
  }
`;
