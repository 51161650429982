import React from "react";
import { colors } from "../../helpers/consts";
import Img from "./Img";
import { BodyText } from "../Typo/BodyText";
import { PlayButtonWrap, StyledPlayButton } from "../Styles/StyledPlayButton";

interface PlayButtonProps {
  children?: JSX.Element;
}

const PlayButton = ({ children }: PlayButtonProps) => {
  return (
    <StyledPlayButton>
      <PlayButtonWrap>
        <svg width="12" height="16" viewBox="0 0 12 16">
          <path
            d="M8,0l8,12H0Z"
            transform="translate(12) rotate(90)"
            fill={colors.white}
          />
        </svg>
      </PlayButtonWrap>
      {children}
    </StyledPlayButton>
  );
};

export default PlayButton;
