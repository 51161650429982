import {
  StyledBanner,
  BannerContent,
  BannerVideo,
} from "../Styles/StyledBanner";

interface Props {
  imgUrl: string;
  children?: JSX.Element | JSX.Element[];
}

const Banner = ({ imgUrl, children }: Props) => {
  return (
    <StyledBanner imgUrl={imgUrl}>
      <BannerContent>{children}</BannerContent>
      <BannerVideo src={imgUrl} loop muted playsInline autoPlay />
    </StyledBanner>
  );
};

export default Banner;
