import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { ArticlesSection } from "./StyledBlogPage";
import { VideoPageSection } from "./StyledVideosPage";
import { StyledVideoModalContent } from "./StyledVideoModalContent";

export const StyledButton = styled.button`
  font-size: 1.2rem;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  display: inline-block;
  padding: 0.7em 1.1em;
  background-color: ${colors.black};
  color: ${colors.white};

  ${breakpoint.phoneLandscape} {
    font-size: 1.4rem;
  }

  ${breakpoint.phone} {
    font-size: 1.4rem;
  }

  &.big {
    padding: 1em 1.5em;
  }

  &.primary {
    background-color: ${colors.orange400};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.orange600};
    }
  }

  &.tertiary {
    background-color: ${colors.white};
    font-weight: bold;
    padding: 0.9em 1.5em;
    color: ${colors.black};
    &:hover {
      background-color: ${colors.gray200};
      color: ${colors.white};
    }
  }

  &.secondary {
    padding: 1em 1.5em;
    background-color: ${colors.black};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.gray400};
    }

    ${StyledVideoModalContent} & {
      width: 30px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    ${VideoPageSection} & , ${ArticlesSection} & {
      margin-top: 30px;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;
