import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { StyledBlogPage } from "../Styles/StyledBlogPage";
import {
  ArticleSection,
  PartnersSection,
} from "../Styles/StyledLandingPage";
import { StyledTextBlock } from "../Styles/StyledTextBlock";
import { StyledVideoModalContent } from "../Styles/StyledVideoModalContent";

export const BlockHeader = styled.h2`
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.1em;
  color: ${colors.black};
  letter-spacing: -0.02em;
  ${breakpoint.phone} {
    font-size: 3rem;
  }
  ${breakpoint.phoneLandscape} {
    font-size: 3rem;
  }
  /* ${StyledTextBlock} & {
    ${breakpoint.tabletPortrait} {
      font-size: 4rem;
    }
    ${breakpoint.phoneLandscape} {
      font-size: 3rem;
    }
  } */
  ${ArticleSection} & {
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }
  ${PartnersSection} & {
    letter-spacing: -0.01em;
  }
  ${StyledBlogPage} & {
    padding-bottom: 50px;
    ${breakpoint.tabletPortrait} {
      padding-bottom: 10px;
    }
  }
  ${StyledVideoModalContent} & {
    padding: 30px 0;
    ${breakpoint.phoneLandscape} {
      padding: 15px 0;
    }
    ${breakpoint.phone} {
      padding: 15px 0;
    }
  }
`;
