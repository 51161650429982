import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";

export const StyledNavbarLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${breakpoint.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
