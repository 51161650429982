import React from "react";
import Img from "./Img";
import { StyledFilter } from "../Styles/StyledFilter";

interface FilterProps {
  onClick: () => void;
  className?: string;
  isActive: boolean;
  children: string;
  color: string;
}

const Filter = ({ onClick, isActive, children, color }: FilterProps) => {
  return (
    <StyledFilter
      onClick={onClick}
      className={isActive ? `active` : ``}
      color={color}>
      {children}
      <Img src={"/icons/CrossIcon.svg"} />
    </StyledFilter>
  );
};

export default Filter;
