
import React, {Fragment, useEffect, useState} from "react";
import Button from "./components/Items/Button";
import FilterGroup, {Tag} from "./components/Items/FilterGroup";
import Footer from "./components/Items/Footer";
import Navbar from "./components/Items/Navbar";
import { PageHeader } from "./components/Typo/PageHeader";
import { Title } from "./components/Typo/Title";
import VideoModal from "./components/Items/VideoModal";

import {
  colors,
  ITEMS_PER_FETCH,
  INITIAL_ITEMS_PER_PAGE,
} from "./helpers/consts";

import {
  FilterGroupContainer,
  FiltersSection,
  StyledVideosPage,
  VideoPageSection,
  VideoSectionWrapper,
} from "./components/Styles/StyledVideosPage";





export interface Group {
  groupId: string;
  name: string;
  thumbnail_url: string;
  videos: string;
  tags: Tag[];
}

interface ApiResponse {
  error: boolean;
  message: string;
  data: Group[];
}


const Videos = () => {
  //const [videoGroups, setVideoGroups] = useState([]);
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const initialData = {
    tags: [{tag_name:'xxx'},{tag_name:'yyy'}]
  }

  const videoGroups = {
    data:[
      {
        video_group_id:{
          videos:[{video_id:0}]
        }
      }
    ]
  }

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [groupsData, setGroups] = useState<Group[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);



  useEffect(() => {
    const fetchData = async () => {
      try {

        const serverUri = 'https://ats.tofo.cloud/api/index.php?scheme=video&action=GetAllGroups';

        let response;


        response = await fetch(serverUri
            + '&' + new URLSearchParams({
              domain: btoa('demo.hazardperception.cz'),
              company: '2',
              token: 'hp',
              lang: 'cs',
            }).toString());

        const responseData: ApiResponse = await response.json();

        //const response: AxiosResponse = await axios.get('https://ats.tofo.cloud/api/index.php?scheme=tests&action=GetTests&token=' + localStorage.getItem('token'));
        setError(responseData.error);
        setErrorMessage(responseData.message);
        setGroups(responseData.data);
        setFilteredGroups(responseData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleTagClick = (tagId: string) => {
    const filtered = groupsData.filter(group => group.tags.some(tag => tag.id === tagId));
    setFilteredGroups(filtered);

  };
  const handleClearFilter = () => {
    setFilteredGroups(groupsData); // Zrušení všech filtrů, zobrazení všech skupin
  };



  return (
    <Fragment>
      <StyledVideosPage>
        <Navbar src={"/logos/LogoColors.svg"} className={"black"} />
        <VideoPageSection>
          <PageHeader>Testy</PageHeader>
          <FiltersSection>
            <FilterGroupContainer className='wrap'>
              <Title>Podle obsahu videa</Title>
              <FilterGroup
                className='wrap'
                onTagClick={handleTagClick}
                onClearFilter={handleClearFilter}

              />
            </FilterGroupContainer>
          </FiltersSection>

          <VideoSectionWrapper>
            {filteredGroups.length==0 && <PageHeader>loading</PageHeader>}
            {
              filteredGroups.map((group: Group) => (
                  <VideoModal
                      videoIds={group.videos}
                      coverSrc={group.thumbnail_url}
                      videoName={group.name}
                      videoType={'hp'}
                      tags={group.tags}
                      key={group.groupId}
                  />
              ))
            }

          </VideoSectionWrapper>

        </VideoPageSection>
        <Footer />
      </StyledVideosPage>
    </Fragment>
  );
};



export default Videos;
