import Spinner from "./Spinner";
import { StyledButton } from "../Styles/StyledButton";

interface ButtonProps {
  children: any;
  className?: string;
  onClick?: () => void;
  href?: string;
  target?: string;
  as?: keyof HTMLElementTagNameMap;
  loading?: boolean;
  type?: string;
}

const Button = ({
  children,
  loading,
  className,
  onClick,
  as,
  type,
}: ButtonProps) => {
  return (
    <StyledButton onClick={onClick} className={className} as={as} >
      {loading && <Spinner color={`white`} />}
      {loading ? `Načítání` : children}
    </StyledButton>
  );
};

export default Button;
