
import { mainTagData, VideoType } from "../../helpers/consts";
import Modal from "./Modal";
import { Tags } from "../Styles/StyledTag";
import Tag from "./Tag";
import { SubHeader } from "../Typo/SubHeader";
import PlayButton from "./PlayButton";
import VideoBanner from "./VideoBanner";
import { VideoInfo } from "../Styles/StyledVideoCard";
import { StyledVideoModal } from "../Styles/StyledVideoModal";
import VideoModalContent from "./VideoModalContent";
import { useParams } from 'react-router-dom';
import {Tag as TagType} from "./FilterGroup";

interface VideoModalProps {
  videoType: VideoType;
  tags: TagType[];
  coverSrc: string;
  videoName: string;
  videoIds: string;
}

const VideoModal = ({
  videoType,
  tags,
  videoName,
  coverSrc,
  videoIds,
}: VideoModalProps) => {

  // Check if the current video ID matches any of the videoIds
  let isOpen = false;
  if(localStorage.getItem('lastOpen')==videoName){
    isOpen= true;
  }


  return (
    <StyledVideoModal>
      <Modal
        defaultOpen={isOpen}
        onOpen={()=>{
          localStorage.setItem('lastOpen',videoName);
        }}
        onClose={()=>{
          localStorage.setItem('lastOpen','');
        }}
        trigger={
          <VideoBanner
            imgUrl={coverSrc}
            labelBg={mainTagData[videoType].color}
            labelTitle={mainTagData[videoType].text}>
            <PlayButton />
            <VideoInfo>
              <Tags>
                {tags.map((tag) => (
                  <Tag key={tag.id}>{tag.name}</Tag>
                ))}
              </Tags>
              <SubHeader>{videoName}</SubHeader>
            </VideoInfo>
          </VideoBanner>
        }
        content={
          <VideoModalContent
            videoName={videoName}
            coverSrc={coverSrc}
            tags={tags}
            videoType={videoType}
            variants={videoIds}
          />
        }
      />
    </StyledVideoModal>
  );
};

export default VideoModal;
