import { StyledFooter, FooterInfo } from "../Styles/StyledFooter";
import Navbar from "./Navbar";
import Img from "./Img";
import { BodyText } from "../Typo/BodyText";

const Footer = () => {
  return (
    <StyledFooter>
      <Navbar
        showLogin={true}
        src={"/logos/LogoColors.svg"}
        className={"black"}
      />
      <FooterInfo>
        <Img src={"/logos/CkpLogo.svg"} />
        <BodyText>
          Vytvořeno v rámci projektu Nová generace testových otázek pro žadatele
          o řidičské oprávnění ve formě HAZARD PERCEPTION VIDEOSEKVENCE
          podpořeného Fondem zábrany škod České kanceláře pojistitelů
        </BodyText>
      </FooterInfo>
    </StyledFooter>
  );
};

export default Footer;
