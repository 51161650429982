import styled from "styled-components";
import { colors, breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";
import { StyledFooter } from "./StyledFooter";

export const StyledNavbar = styled.nav`
  width: ${u(14)};
  padding-top: ${u(0.5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: ${u(1)};
  ${breakpoint.phone} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  &.white {
    color: ${colors.white};
  }
  &.black {
    position: relative;
    color: ${colors.black};
  }

  ${StyledFooter} & {
    left: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    img {
      width: 150px;
    }
    ${breakpoint.tabletLandscape} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    ${breakpoint.tabletPortrait} {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 40px;
      img {
        width: 120px;
      }
    }
    ${breakpoint.phone} {
    }
  }
`;
