import { motion } from "framer-motion";
import styled from "styled-components";
import { StyledButton } from "./StyledButton";

export const StyledSpinner = styled(motion.span)`
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  ${StyledButton} & {
    margin-right: 10px;
  }
`;
