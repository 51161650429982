import Link from "./Link";
import { StyledNavLink } from "../Styles/StyledNavLink";

interface Props {
  children: string;
  isActive: any;
  href: string;

  onClick?: () => void;
  scroll?: boolean;
}

const NavLink = ({ children, isActive, href, onClick }: Props) => {
  return (
    <StyledNavLink>
      <Link href={href} className={isActive ? "active" : ""} onClick={onClick}>
        {children}
      </Link>
    </StyledNavLink>
  );
};

export default NavLink;
