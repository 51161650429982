import styled from "styled-components";
import { colors } from "../../helpers/consts";
import { StyledTextInput } from "../Styles/StyledTextInput";
import { StyledVideoModalInstruction } from "../Styles/StyledVideoModalInstruction";

export const Caption = styled.span`
  font-size: 1.2rem;
  ${StyledTextInput} & {
    color: ${colors.red};
    display: block;
    padding: 0.5em 0;
  }
`;
