import styled from "styled-components";

export const StyledArticleCard = styled.a`
  display: block;
`;

export const ArticleCardCoverWrapper = styled.div`
  position: relative;
  padding-bottom: ${(9 / 16) * 100}%;
  overflow: hidden;
`;
