import styled from "styled-components";
import { colors, breakpoint } from "../../helpers/consts";

export const StyledTextInput = styled.div`
  margin: 1em 0;
`;
export const Field = styled.input`
  display: block;
  width: 100%;
  font-size: 1.2rem;
  padding: 0.6em 1em;
  border: 1px solid ${colors.black};
  background-color: transparent;
  &:focus {
    outline: none;
  }
  ${breakpoint.phoneLandscape} {
    font-size: 1.6rem;
  }
  ${breakpoint.phone} {
    font-size: 1.6rem;
  }
`;
export const Label = styled.label`
  display: block;
  width: 100%;
  font-size: 1.1rem;
  letter-spacing: 0.02em;
  padding: 0.5em 0;
  ${breakpoint.phoneLandscape} {
    font-size: 1.6rem;
    letter-spacing: 0;
  }
  ${breakpoint.phone} {
    font-size: 1.6rem;
    letter-spacing: 0;
  }
`;
