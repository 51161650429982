import styled from "styled-components";
import u from "../../helpers/unit";
import { StyledArticleDetailPage } from "./StyledArticleDetailPage";

export const StyledBanner = styled.div<{ imgUrl: string }>`
  width: 100%;
  height: 100vh;
  padding: ${u(1)};
  display: flex;
  align-items: flex-end;
  position: relative;
  ${StyledArticleDetailPage} & {
    height: 75vh;
  }
`;

export const BannerContent = styled.div``;

export const BannerVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  inset: 0;
  z-index: -1;
`;
