
import { StyledLink } from "../Styles/StyledLink";

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  href:string;
  className?: string;
  onClick?: () => void;
  as?: keyof HTMLElementTagNameMap;
}

const Link = ({ children, href, as, className, ...rest }: Props) => {
  return (
    <a href={href}>
      <StyledLink as={as} className={className} {...rest}>
        {children}
      </StyledLink>
    </a>
  );
};

export default Link;
