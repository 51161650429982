import styled, { css } from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { ArticleContainer } from "../Styles/StyledArticleDetailPage";
import { StyledButton } from "../Styles/StyledButton";
import { StyledFooter } from "../Styles/StyledFooter";
import { StyledVideoBanner } from "../Styles/StyledVideoBanner";
import { VideoModalInstructions } from "../Styles/StyledVideoModalInstruction";
import { StyledVideoModalVariant } from "../Styles/StyledVideoModalVariant";

export const BodyText = styled.p`
  font-size: 1.2rem;
  letter-spacing: 0.02em;

  ${StyledFooter} & {
    color: ${colors.gray200};
  }

  ${VideoModalInstructions} & {
    font-weight: 300;
    font-size: 1.4rem;
    padding-bottom: 1em;

    span {
      padding-right: 2em;
    }
  }

  ${StyledVideoModalVariant} & {
    padding-left: 5px;
  }

  ${StyledVideoBanner} & {
    &.btn-descriptor {
      color: ${colors.orange400};
      font-size: 1.2rem;
      position: absolute;
      left: 50%;
      top: 150%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      width: 200px;
      text-align: center;
    }
    &.label {
      color: ${colors.white};
      display: inline-block;
      padding: 10px 15px 9px 15px;
      vertical-align: middle;
      text-transform: uppercase;
      position: absolute;
      left: 0;
      top: 40px;

      ${Object.keys(colors).map(
        (color) =>
          css`
            &.${color} {
              background-color: ${ colors[color]};
            }
          `
      )}
      ${breakpoint.tabletLandscape} {
        top: 30px;
        padding: 8px 13px;
      }
      ${breakpoint.miniPhone} {
        top: 20px;
      }
    }
  }

  ${StyledButton} & {
    padding: 0;
  }
`;
