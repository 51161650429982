import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { StyledTextBlock } from "../Styles/StyledTextBlock";
import { VideoInfo } from "../Styles/StyledVideoCard";
import { StyledVideoModalContent } from "../Styles/StyledVideoModalContent";

export const SubHeader = styled.h4`
  font-size: 1.8rem;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 400;
  ${StyledTextBlock} & {
    color: ${colors.orange400};
  }
  ${VideoInfo} & {
    color: ${colors.white};
    font-size: 3rem;
    letter-spacing: -0.02rem;
    padding: 0.25em 0 0;
    ${breakpoint.tabletLandscape} {
      font-size: 2.1rem;
    }
    ${breakpoint.phone} {
      font-size: 2.1rem;
    }
  }
  ${StyledVideoModalContent} & {
    font-size: 1.4rem;
    padding-top: 20px;
  }
`;
