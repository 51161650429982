import { BodyText } from "../Typo/BodyText";
import { Caption } from "../Typo/Caption";
import { StyledVideoModalInstruction } from "../Styles/StyledVideoModalInstruction";

interface VideoModalInstructionProps {
  children: string;
  order: string;
}

const VideoModalInstruction = ({
  children,
  order,
}: VideoModalInstructionProps) => {
  return (
    <StyledVideoModalInstruction>
      <Caption>{order}</Caption>
      <BodyText>{children}</BodyText>
    </StyledVideoModalInstruction>
  );
};

export default VideoModalInstruction;
