import * as Dialog from "@radix-ui/react-dialog";
import Img from "./Img";
import Scrollbar from "./Scrollbar";
import {
  Close,
  ModalContent,
  ModalTrigger,
  Overlay,
  StyledModal,
} from "../Styles/StyledModal";
import {useEffect, useState} from "react";

export interface ModalProps {
  content: React.ReactNode;
  trigger: React.ReactNode;
  defaultOpen?: boolean;
  onOpen?: () => void;   // Optional onOpen callback prop
  onClose?: () => void;  // Optional onClose callback prop
}

const Modal = ({ content, trigger, defaultOpen, onOpen, onClose }: ModalProps) => {

  const [isOpen, setIsOpen] = useState(defaultOpen);

  // Effect to handle open and close callbacks
  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen(); // Call onOpen when the modal opens
    } else if (!isOpen && onClose) {
      onClose(); // Call onClose when the modal closes
    }
  }, [isOpen, onOpen, onClose]);

  return (
    <StyledModal defaultOpen={defaultOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild onClick={() => setIsOpen(true)}>{trigger}</ModalTrigger>
      <Dialog.Portal>
        <ModalContent>
          <Close onClick={onClose} >
            <Img src='/icons/CrossIcon.svg' />
          </Close>
          {content}
        </ModalContent>
        <Overlay />
      </Dialog.Portal>
    </StyledModal>
  );
};

export default Modal;
