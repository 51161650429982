import styled from "styled-components";

export const StyledTag = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.07em;
  text-transform: uppercase;
`;

export const Tags = styled.div`
  padding-top: 10px;
  display: flex;
  flex: wrap;
  gap: 10px;
`;
