import React from 'react';
import { Fragment } from "react";

import './App.css';
import {Intro, PartnersSection, StyledLandingPage} from "./components/Styles/StyledLandingPage";
import Navbar from "./components/Items/Navbar";
import Banner from "./components/Items/Banner";
import Button from "./components/Items/Button";
import {PageHeader} from "./components/Typo/PageHeader";
import {BlockQuote} from "./components/Typo/BlockQuote";
import Footer from "./components/Items/Footer";
import LogoGrid from "./components/Items/LogoGrid";
import {BlockHeader} from "./components/Typo/BlockHeader";
import {Link} from "react-router-dom";


function App() {
  return (
      <Fragment>
          <StyledLandingPage>
              <Navbar src={"/logos/LogoWhite.svg"} className={"white"} />

              <Banner imgUrl={"/hero-loop-2.mp4"}>
                  <PageHeader>
                      Odhalte nebezpečí na cestách předtím, než se stanou
                  </PageHeader>
                  <Link to={"/videos"}>
                      <Button
                          className='primary big'>
                          Odhalit nebezpečí
                      </Button>
                    </Link>

              </Banner>

              <Intro>
                  <BlockQuote>
                      Vyzkoušejte si nanečisto, jak byste se zachovali v nebezpečných
                      situacích na českých silnicích. Natočili jsme nejčastější hrozby,
                      které vás mohou potkat, abyste si je vyzkoušeli a naučili se na ně
                      reagovat.
                  </BlockQuote>
                  <BlockQuote>Zjistěte, jestli byste vyvázli.</BlockQuote>
              </Intro>


              <PartnersSection>
                  <BlockHeader>Partneři</BlockHeader>
                  <LogoGrid />
              </PartnersSection>
              <Footer />
          </StyledLandingPage>
      </Fragment>
  );
}

export default App;
