import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";

export const StyledLogin = styled.form`
  padding: 30px;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${breakpoint.phone} {
    padding: 15px;
  }
`;
