import React, { useEffect, useRef, useState } from "react";
import Filter from "./Filter";
import { StyledFilterGroup } from "../Styles/StyledFilterGroup";
import {colors} from "../../helpers/consts";


export interface Tag {
  id: string;
  name: string;
  name_cs: string;
  name_en: string;
}


interface ApiResponse {
  error: boolean;
  message: string;
  data: Tag[];
}

interface TagsProps {
  onTagClick: (tagId: string) => void;
  onClearFilter: () => void;
  className: string
}



const FilterGroup = ({ onTagClick, onClearFilter, className }: TagsProps) => {
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tagsData, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const serverUri = 'https://ats.tofo.cloud/api/index.php?scheme=video&action=GetAllTags';

        const response = await fetch(serverUri);
        const responseData: ApiResponse = await response.json();

        setError(responseData.error);
        setErrorMessage(responseData.message);
        setTags(responseData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <StyledFilterGroup className={className}>
      {tagsData.map((tag) => (
          <Filter
              color={colors.orange400}
              isActive={false}
              key={tag.id}
              onClick={() => onTagClick(tag.id)}
          >
            {tag.name}
          </Filter>
      ))}
    </StyledFilterGroup>
  );
};

export default FilterGroup;
