import { motion } from "framer-motion";
import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { StyledArrowLink } from "./StyledArrowLink";
import { ArticleCardCoverWrapper } from "./StyledArticleCard";
import { StyledFilter } from "./StyledFilter";
import { StyledFooter } from "./StyledFooter";
import { StyledLogoGrid } from "./StyledLogoGrid";
import { StyledPlayButton } from "./StyledPlayButton";
import { VideoBannerCover } from "./StyledVideoBanner";
import { VideoModalInfo } from "./StyledVideoModalContent";
import { StyledVideoModalVariant } from "./StyledVideoModalVariant";

export const StyledImg = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: contain;

  ${ArticleCardCoverWrapper} & {
    object-fit: cover;
    position: absolute;
  }

  ${StyledArrowLink} & {
    width: 30px;
    height: auto;
    float: left;
  }

  ${VideoBannerCover} & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${VideoModalInfo} & {
    width: 15px;
    height: 15px;
  }

  ${StyledPlayButton} & {
    width: 20px;
    height: 20px;
  }

  ${StyledVideoModalVariant} & {
    background-color: ${colors.green400};
    border-radius: 50%;
    padding: 3px;
    margin-left: 10px;
    display: block;
    width: 13px;
    height: 13px;
  }

  ${StyledLogoGrid} & {
    width: 100%;
    height: 80px;
    mix-blend-mode: luminosity;

    ${breakpoint.tabletPortrait} {
      width: 70%;
    }
    ${breakpoint.phone} {
      width: 100%;
      max-width: 90px;
      max-height: 60px;
    }
  }

  ${StyledFooter} & {
    width: unset;
    height: unset;
  }

  ${StyledFilter} & {
    margin-left: 10px;
    display: block;
    width: 13px;
    height: 13px;
  }
`;
