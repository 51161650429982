import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import percentToRgbHex from "../../helpers/percentToRgbHex";
import { StyledNavbar } from "./StyledNavbar";
import { StyledVideoModal } from "./StyledVideoModal";

export const StyledModal = styled(Dialog.Root)``;

export const ModalContent = styled(Dialog.DialogContent)`
  background-color: ${colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px ${colors.black + percentToRgbHex(30)};
  z-index: 99;
  ${StyledNavbar} & {
    ${breakpoint.phone} {
      top: 35%;
    }
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  background-color: ${colors.orange400 + percentToRgbHex(90)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  z-index: 98;
`;

export const ModalTrigger = styled(Dialog.Trigger)`
  background-color: transparent;
  border: none;
  width: inherit;
  ${StyledVideoModal} & {
    width: 100%;
  }
`;

export const Close = styled(Dialog.Close)`
  background-color: ${colors.black};
  height: 25px;
  width: 25px;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 15px;
  border: 0;
  cursor: pointer;
  z-index: 3;
  &:hover {
    background-color: ${colors.gray400};
  }
`;
