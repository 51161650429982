import Button from "./Button";
import Img from "./Img";
import { BodyText } from "../Typo/BodyText";
import { StyledVideoModalVariant } from "../Styles/StyledVideoModalVariant";

interface VideoModalVariantProps {
  variantText: string;
  wasPlayed: boolean;
  onClick: () => void;
}

const VideoModalVariant = ({
  variantText,
  wasPlayed,
  onClick,
}: VideoModalVariantProps) => {
  return (
    <StyledVideoModalVariant
      className={wasPlayed ? "was-played" : ""}
      onClick={onClick}>
      <BodyText>{variantText}</BodyText>
      {wasPlayed && <Img src="/icons/CheckIcon.svg" />}
    </StyledVideoModalVariant>
  );
};

export default VideoModalVariant;
