import React from "react";
import { StyledTag } from "../Styles/StyledTag";

interface TagProps {
  children: string;
}

const Tag = ({ children }: TagProps) => {
  return <StyledTag>{children}</StyledTag>;
};

export default Tag;
