import styled from "styled-components";

export const StyledFilterGroup = styled.div`
  display: flex;
  gap: 5px;

  &.no-wrap {
    flex-wrap: nowrap;
  }
  &.wrap {
    flex-wrap: wrap;
  }
`;
