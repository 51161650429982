import styled from "styled-components";

export const StyledVideoBanner = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;

export const VideoBannerCover = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;
  &:before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }
`;

export const VideoBannerChildren = styled.div`
  position: absolute;
  inset: 0;
  z-index: 3;
`;
