import styled from "styled-components";
import { colors } from "../../helpers/consts";

export const StyledFilter = styled.button<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.gray400};
  font-size: 1.2rem;
  background-color: ${colors.white};
  padding: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.gray500};
  }

  &.active {
    background-color: ${({ color }) => color};
    color: ${colors.white};
    border: 1px solid ${({ color }) => color};
  }
`;
