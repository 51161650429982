import styled from "styled-components";

export const VideoModalInstructions = styled.ol`
  list-style: none;
`;

export const StyledVideoModalInstruction = styled.li`
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 0px;
  padding: 0.25em 0;
`;
