import styled from "styled-components";
import { colors, breakpoint } from "../../helpers/consts";

export const StyledPlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PlayButtonWrap = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange400};
  ${breakpoint.tabletLandscape} {
    width: 50px;
    height: 50px;
  }
  ${breakpoint.phone} {
    width: 40px;
    height: 40px;
  }
`;
