import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { VideoSection } from "./StyledLandingPage";
import { StyledVideoBanner } from "./StyledVideoBanner";

export const StyledVideoCard = styled.div`
  position: relative;
  ${VideoSection} & {
    grid-area: videoblock;
  }
`;

export const VideoInfo = styled.div`
  padding-top: 1em;
  ${StyledVideoBanner} & {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: ${colors.white};
    z-index: 2;
    ${breakpoint.phoneLandscape} {
      bottom: 15px;
      left: 15px;
    }
    ${breakpoint.phone} {
      bottom: 15px;
      left: 15px;
    }
  }
`;
