import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createGlobalStyle} from "styled-components";
import {breakpoint} from "./helpers/consts";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Videos from "./videos";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        //errorElement: <ErrorPage />,
    },

    {
        path: "videos",
        element: <Videos />,
    }

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    font-family: 'pragmatica-extended', Arial, sans-serif;
    font-display: swap;
  }
  html {
    font-size: 62.5%;
    ${breakpoint.monitor} {
      font-size: 80%;
    }
  }
  body {
    box-sizing: border-box;
  }
  ul {
    list-style: none
  }
`;


root.render(
  <React.StrictMode>
      <GlobalStyle />
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
