import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledLandingPage = styled.div``;

export const Intro = styled.div`
  padding: ${u(1)};
  max-width: ${u(10)};
  ${breakpoint.tabletPortrait} {
    max-width: ${u(12)};
  }
  ${breakpoint.phone} {
    max-width: ${u(14)};
    padding: ${u(2)} ${u(1)};
  }
`;

export const VideoSectionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: ${u(1)};
  padding: 0 ${u(1)};
`;

export const VideoSection = styled.div`
  display: grid;
  grid-template-areas: "textblock videoblock";
  grid-template-columns: ${u(5)} ${u(8)};
  grid-gap: ${u(1)};
  &:nth-child(2n) {
    grid-template-areas: "videoblock textblock";
    grid-template-columns: ${u(8)} ${u(5)};
  }
  ${breakpoint.tabletPortrait} {
    grid-template-columns: unset;
    grid-template-areas: "videoblock" "textblock";
    &:nth-child(2n) {
      grid-template-areas: "videoblock" "textblock";
      grid-template-columns: unset;
    }

    ${breakpoint.phone} {
      padding-bottom: ${u(2)};
    }
  }
`;

export const ArticleSection = styled.div`
  padding: ${u(1)};
  ${breakpoint.phone} {
    padding: ${u(2)} ${u(1)};
  }
`;

export const ArticleHeader = styled.div``;

export const PartnersSection = styled.div`
  padding: 0 ${u(1)};
`;
