import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import { FilterGroupContainer } from "../Styles/StyledVideosPage";
import { StyledArrowLink } from "../Styles/StyledArrowLink";
import { StyledArticleCard } from "../Styles/StyledArticleCard";
import { VideoInfo } from "../Styles/StyledVideoCard";
import { StyledVideoModalContent } from "../Styles/StyledVideoModalContent";
import { VideoModalInstructions } from "../Styles/StyledVideoModalInstruction";

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  ${VideoInfo} & {
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    .big {
      font-size: 2.1rem;
    }
  }
  ${StyledArticleCard} & {
    font-size: 2.4rem;
    padding: 0.5em 0;
    ${breakpoint.phoneLandscape} {
      font-size: 1.8rem;
    }
    ${breakpoint.phone} {
      font-size: 1.8rem;
    }
  }
  ${StyledArrowLink} & {
    font-size: 1.2rem;
    padding: 2em 0;
  }
  ${VideoModalInstructions} & {
    font-size: 2.1rem;
    color: ${colors.orange400};
    padding-bottom: 1em;
  }
  ${StyledVideoModalContent} & {
    color: ${colors.orange400};
    font-size: 2.1rem;
    margin-bottom: 5px;
    ${breakpoint.phone} {
      margin-bottom: 10px;
    }
  }

  ${FilterGroupContainer} & {
    font-size: 1.6rem;
    font-weight: 500;
    padding-bottom: 1em;
  }
`;
