import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledVideosPage = styled.div``;

export const VideoPageSection = styled.div`
  padding: ${u(1)};
`;

export const FilterGroupContainer = styled.div``;

export const FiltersSection = styled.div`
  padding: ${u(0.5)} 0 ${u(1)} 0;
  display: flex;
  gap: 50px;

  ${breakpoint.tabletPortrait} {
    flex-direction: column;
    gap: 20px;
  }

  ${breakpoint.phone} {
    padding: ${u(2)} 0;
  }
`;

export const VideoSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${u(0.5)};
  column-gap: 15px;

  ${breakpoint.tabletPortrait} {
    grid-template-rows: auto;
    grid-template-columns: unset;
  }
  ${breakpoint.phoneLandscape} {
    grid-template-rows: auto;
    grid-template-columns: unset;
  }
  ${breakpoint.phone} {
    grid-template-rows: auto;
    grid-template-columns: unset;
  }
`;
