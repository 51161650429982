import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledFooter = styled.div`
  padding: ${u(1)};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoint.tabletLandscape} {
    flex-direction: column;
    height: auto;
    gap: 50px;
  }

  ${breakpoint.phone} {
    padding: ${u(2)} ${u(1)};
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-end;
  text-align: end;
  ${breakpoint.tabletLandscape} {
    align-items: flex-start;
    text-align: start;
  }
`;
