import styled from "styled-components";
import { breakpoint, colors } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledArticleDetailPage = styled.div``;

export const ArticleContainer = styled.div`
  padding: ${u(0.5)} ${u(4)};
  h1 {
    font-size: 5rem;
    letter-spacing: -0.02em;
    line-height: 1.1em;
    ${breakpoint.tabletPortrait} {
      font-size: 3.5rem;
    }
  }

  h3 {
    font-size: 2.3rem;
    color: ${colors.orange400};
    font-weight: 400;
    ${breakpoint.tabletPortrait} {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 2.1rem;
    font-weight: 200;
    color: ${colors.gray500};
    ${breakpoint.tabletPortrait} {
      font-size: 1.4rem;
    }
  }

  h2 {
    font-size: 3.6rem;
    font-weight: 400;
    ${breakpoint.tabletPortrait} {
      font-size: 2.5rem;
    }
  }

  h4 {
    font-size: 2.1rem;
    color: ${colors.orange400};
    font-weight: 400;
    ${breakpoint.tabletPortrait} {
      font-size: 1.4rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    padding: 30px 0;
  }

  ${breakpoint.tabletPortrait} {
    padding: ${u(1)} ${u(3)};
  }
  ${breakpoint.phone} {
    padding: ${u(1)} ${u(1)};
  }
`;
