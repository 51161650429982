import { useState } from "react";
import { ColorKeys } from "../../helpers/consts";
import Img from "./Img";
import {
  StyledVideoBanner,
  VideoBannerChildren,
  VideoBannerCover,
} from "../Styles/StyledVideoBanner";

interface BannerProps {
  imgUrl: string;
  labelTitle: string;
  labelBg: ColorKeys;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
}

const VideoBanner = ({
  imgUrl,
  labelBg,
  labelTitle,
  children,
  onClick,
}: BannerProps) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledVideoBanner
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseOut={() => setHover(false)}>
      <VideoBannerCover>
        <Img src={imgUrl} animate={hover ? { scale: 1.03 } : { scale: 1 }} />
      </VideoBannerCover>
      {/* <BodyText className={`label ${labelBg}`}>{labelTitle}</BodyText> */}
      <VideoBannerChildren>{children}</VideoBannerChildren>
    </StyledVideoBanner>
  );
};

export default VideoBanner;
