import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";

export const StyledNavLink = styled.div`
  padding: 0 10px;
  float: left;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${breakpoint.phone} {
    padding: 5px 0;
  }
`;
