import React from "react";
import { ColorKeys, colors } from "../../helpers/consts";
import { StyledSpinner } from "../Styles/StyledSpinner";

interface SpinnerProps {
  color?: ColorKeys;
}

const Spinner = ({ color }: SpinnerProps) => {
    const fillColor = color ? colors[color] : undefined;


    return (
    <StyledSpinner
      animate={{ rotate: [0, 360] }}
      style={{ transformOrigin: `center` }}
      transition={{ repeat: Infinity, ease: `linear` }}>
      <svg x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18">
        <path
          fill={fillColor}
          d="M9,18c-5,0-9-4-9-9s4-9,9-9v2C5.1,2,2,5.1,2,9s3.1,7,7,7s7-3.1,7-7h2C18,14,14,18,9,18z"
        />
      </svg>
    </StyledSpinner>
  );
};

export default Spinner;
