import React, { useRef } from "react";
import SimpleBar from "simplebar-react";
import { StyledScrollbar } from "../Styles/StyledScrollbar";

interface Props {
  children: JSX.Element;
  style: React.CSSProperties;
  useScrollbar?: boolean;
}
const Scrollbar = ({ children, style, useScrollbar = true }: Props) => {
  const scrollbarRef = useRef(null);

  return useScrollbar ? (
    <StyledScrollbar>
      <SimpleBar ref={scrollbarRef} autoHide={false} style={style}>
        <>{children}</>
      </SimpleBar>
    </StyledScrollbar>
  ) : (
    children
  );
};

export default Scrollbar;
