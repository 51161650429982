import { HTMLMotionProps } from "framer-motion";
import { StyledImg } from "../Styles/StyledImg";

interface Props extends HTMLMotionProps<"img"> {
  src: string;
  className?: string;
}

const Img = ({ src, ...rest }: Props) => {
  return <StyledImg src={src} {...rest} />;
};

export default Img;
