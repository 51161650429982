import Img from "./Img";
import { StyledLogoGrid } from "../Styles/StyledLogoGrid";

const partnerLogos = [
  {
    logoSrc: "/partners/asociace-autoskol.png",
  },
  {
    logoSrc: "/partners/ministrstvo-dopravy.png",
  },
  {
    logoSrc: "/partners/pcr.png",
  },
  {
    logoSrc: "/partners/tym-silnicni-bezpecnosti.svg",
  },
  {
    logoSrc: "/partners/vize.png",
  },
  {
    logoSrc: "/partners/autoklub-ceske-republiky.png",
  },
  {
    logoSrc: "/partners/autoskolsky-ombudsman.png",
  },
  {
    logoSrc: "/partners/autoklub-ceske-republiky-2.svg",
  },
  {
    logoSrc: "/partners/besip.png",
  },
  {
    logoSrc: "/partners/asociace-dopravnich-psychologu.png",
  },
];

const LogoGrid = () => {
  return (
    <StyledLogoGrid>
      {partnerLogos.map((partnerLogo, i) => {
        return <Img key={i} src={partnerLogo.logoSrc} />;
      })}
    </StyledLogoGrid>
  );
};

export default LogoGrid;
