import {mainTagData, VideoType} from "../../helpers/consts";
import {Tags} from "../Styles/StyledTag";
import Tag from "./Tag";
import {BlockHeader} from "../Typo/BlockHeader";
import {SubHeader} from "../Typo/SubHeader";
import {Title} from "../Typo/Title";
import VideoBanner from "./VideoBanner";
import {VideoModalInstructions} from "../Styles/StyledVideoModalInstruction";
import {StyledVideoModalContent, VideoModalInfo,} from "../Styles/StyledVideoModalContent";
import {VideoModalVariants} from "../Styles/StyledVideoModalVariant";
import VideoModalInstruction from "./VideoModalInstruction";
import VideoModalVariant from "./VideoModalVariant";
import PlayButton from "./PlayButton";
import Scrollbar from "./Scrollbar";
import u from "../../helpers/unit";
import {getRandom} from "../../helpers/getRandom";
import {Tag as TagType} from "./FilterGroup";

interface VideoModalContentProps {
  tags: TagType[];
  videoType: VideoType;
  variants: string;
  coverSrc: string;
  videoName: string;
}

const indexToNumList = (i:number) => (i + 1 < 10 ? `0${i + 1}` : `${i + 1}`);

const VideoModalContent = ({
                             tags,
                             videoType,
                             variants,
                             coverSrc,
                             videoName,
                           }: VideoModalContentProps) => {

  const variantsExp = variants.split(',');

  const dataByVideoType = mainTagData[videoType];
  const hasVariants = variantsExp.length > 1;
  const randomIndex = getRandom(variantsExp.length - 1);


  return (
      <Scrollbar style={{ maxHeight: "80vh", width: u(14) }}>
        <StyledVideoModalContent>
          <VideoBanner
              onClick={() =>
                  window.location.href="https://app.ats.tofo.cloud/player/0/"+variantsExp[randomIndex]

                  //console.log("opravit",variants,randomIndex,variantsExp[randomIndex])
              }
              imgUrl={coverSrc}
              labelTitle={dataByVideoType.text}
              labelBg={dataByVideoType.color}>
            <PlayButton />

          </VideoBanner>
          <VideoModalInfo>
            <div>
              <Tags>
                {tags.map((tag) => (
                    <Tag key={tag.id}>{tag.name}</Tag>
                ))}
              </Tags>
              <BlockHeader>{videoName}</BlockHeader>
            </div>
            <div>
              <Title>Instrukce k testu</Title>
              <VideoModalInstructions>
                {dataByVideoType.instructions?.map((instruction, i) => (
                    <VideoModalInstruction
                        key={instruction}
                        order={indexToNumList(i)}>
                      {instruction}
                    </VideoModalInstruction>
                ))}
              </VideoModalInstructions>
            </div>
            {hasVariants && (
                <div>
                  <SubHeader>Vybrat variantu testu</SubHeader>
                  <VideoModalVariants>
                    {variantsExp.map((variant, i) => (
                        <VideoModalVariant
                            key={i}
                            variantText={indexToNumList(i)}
                            wasPlayed={false}
                            onClick={() => {
                                window.location.href="https://app.ats.tofo.cloud/player/0/"+variant

                                //console.log("opravitX",i,variant);
                            }}
                        />
                    ))}
                  </VideoModalVariants>
                </div>
            )}
          </VideoModalInfo>
        </StyledVideoModalContent>
      </Scrollbar>
  );
};

export default VideoModalContent;
